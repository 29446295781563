import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { ConstLocalStorage } from "src/constants/_localStorage";
import { genericErrorHandler } from "src/utils/_genericErrorHandler";
import { getLocalStoredData } from "src/utils/_localStorage";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE;

// Axios interceptor to inject the token to requests
axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const token = getLocalStoredData(ConstLocalStorage.token);
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  request.headers["Language"] = getLocalStoredData(ConstLocalStorage.language);
  return request;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    // Handle errors globally using the provided genericErrorHandler
    genericErrorHandler(error);
    return Promise.reject(error.response);
  }
);

const apiClient = axios;

export default apiClient;
