import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/admin/edit.svg";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";

const TableActions = ({ rowId }: { rowId: string }) => {
  const { t } = useTranslation("general");
  const navigate = useNavigate();

  const navigateToEditPage = () => {
    navigate(`/customers/${rowId}/edit`);
  };

  const navigateCustomersDetails = (id: any) => {
    navigate(`/jazar-customers/${id}`);
  };
  
  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={t("edit")} onClick={navigateToEditPage}>
        <IconButton>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("details")}>
        <IconButton>
          <VisibilityOutlinedIcon
            fontSize="small"
            onClick={(id) => navigateCustomersDetails(id)}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
export default TableActions;
