import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPasswordPage from "src/pages/auth/forgot-password";
import LoginPage from "src/pages/auth/login";
import { PublicRoutes } from "./public-routes";
import { PrivateRoutes } from "./private-routes";
import ResetPasswordPage from "src/pages/auth/reset-password";
import { Layout } from "src/shared/layout";
import MyProfile from "src/pages/my- profile";
import InternalUsers from "src/pages/internal-users";
import JazarCustomers from "src/pages/jazar-customers";
import RolesManagement from "src/pages/roles-management";
import WebsiteCms from "src/pages/website-cms";
import AddEditRole from "src/pages/roles-management/add-edit-role";
import EditCustomer from "src/pages/jazar-customers/add-edit-customers";
import AddEditPackage from "src/pages/website-cms/tabs/package-list/add-edit-package";

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route element={<LoginPage />} path="/" />
          <Route element={<LoginPage />} path="/login" />
          {/* <Route element={<ForgotPasswordPage />} path="/forgot-password" />
          <Route element={<ResetPasswordPage />} path="/reset-password/:id" /> */}
        </Route>
        <Route element={<PrivateRoutes />}>
          {/* MyProfile */}
          <Route element={<MyProfile />} path="/profile" />
          <Route element={<Layout />}>
            {/* Settings */}
            <Route element={<InternalUsers />} path="/users" />
            <Route element={<RolesManagement />} path="/roles" />
            <Route element={<AddEditRole />} path="/roles/add" />
            <Route element={<AddEditRole />} path="/roles/:id/edit" />
            <Route element={<JazarCustomers />} path="/customers" />
            <Route element={<EditCustomer />} path="/customers/:id/edit" />
            <Route element={<h2>Data</h2>} path="/data" />
            <Route element={<WebsiteCms />} path="/cms" />
            <Route element={<AddEditPackage />} path="/cms/add-package" />
            <Route element={<AddEditPackage />} path="/cms/packages/:id/edit" />
            {/* <Route
              element={<AddEditRole />}
              path="/settings/roles/add-new-role"
            />
            <Route element={<AddEditRole />} path="/settings/roles/:id/edit" /> */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
