import { Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoBox = ({
  text,
  variant = "warning",
}: {
  text: string;
  variant?: "warning" | "error";
}) => {
  return (
    <Stack
      width={"100%"}
      bgcolor={variant === "warning" ? "#FFF3EA" : "#FEE4E2"}
      borderRadius={"4px"}
      padding={1}
      direction={"row"}
      gap={"10px"}
    >
      <InfoOutlinedIcon color={variant === "warning" ? "primary" : "error"}  />
      <Typography color={variant === "warning" ? "black" : "error"}>{text}</Typography>
    </Stack>
  );
};

export default InfoBox;
