import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPropsTypes {}

export const NoData: React.FC<IPropsTypes> = () => {
  const { t } = useTranslation("general");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={280}
      p={3}
    >
      <Typography fontWeight={600} variant="h6" textAlign={"center"}>
       {t("NoData")}
      </Typography>

      <Typography textAlign={"center"}>
        {t("doubleCheck")}
      </Typography>
    </Box>
  );
};
