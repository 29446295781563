import { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";
import { useTranslation } from "react-i18next";
import { useLogoutMutation } from "src/api/auth";
import { notificationsState } from "src/store/notifications";
import LanguageIcon from "@mui/icons-material/Language";
import englandIcon from "src/assets/svg-icons/flags/england.svg";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setLocalStoredData } from "src/utils/_localStorage";
import { ConstLocalStorage } from "src/constants/_localStorage";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { useNavigate } from "react-router-dom";

const JazrAppBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("auth");
  const [auth, setAuth] = useRecoilState(authState);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openE2 = Boolean(anchorE2);

  // APIS
  const { mutate, status, error } = useLogoutMutation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickE2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseE2 = () => {
    setAnchorE2(null);
  };

  const { user } = auth;

  const handleLogout = () => {
    mutate();
  };

  const handleLanguageChange = (language: "en" | "ar") => {
    i18n.changeLanguage(language);
    setLocalStoredData(ConstLocalStorage.language, language);
    setAnchorEl(null);
  };

  const initials = user?.name
    .split(" ")
    .map((word: string) => word.charAt(0))
    .join("");

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== hasScrolled) {
        setHasScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  useEffect(() => {
    if (status === "success") {
      localStorage.clear();
      setAuth({ user: null, token: "" });
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  console.log("userrr", { user });

  return (
    <AppBar
      position="sticky"
      color="default"
      //   elevation={hasScrolled ? 0 : 0}
      elevation={1}
      sx={{
        backgroundColor: "#fff",
        boxShadow: hasScrolled
          ? "boxShadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"
          : "unset",
      }}
      style={{ transition: "box-shadow 0.3s" }}
    >
      <Box
        p={"2px 30px"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        gap={1}
      >
        {/* <IconButton >
          <NotificationsActiveOutlinedIcon sx={{ color: "#1D2939" }} />
        </IconButton> */}
        <IconButton onClick={handleClickE2} size="small">
          <LanguageIcon sx={{ color: "#1D2939" }} />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorE2}
          open={openE2}
          onClose={handleCloseE2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleLanguageChange("en")} sx={{ gap: 1 }}>
            <img src={englandIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              English
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange("ar")} sx={{ gap: 1 }}>
            <img src={saudiIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              العربية
            </Typography>
          </MenuItem>
        </Menu>

        {/* avatar */}
        <Avatar
          variant="rounded"
          src={""}
          alt=""
          sx={{ cursor: "pointer", width: "30px", height: "30px" }}
          onClick={handleClick}
        >
          <Typography textTransform={"uppercase"}>{initials}</Typography>
        </Avatar>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ mt: 0.5 }}
        >
          {/* <MenuItem sx={{ cursor: "text", pointerEvents: "initial" }}>
            {t("companyId")}: {user.company?.reference_number}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              navigate("/profile");
            }}
          >
            {t("profile")}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t("logout")}</MenuItem>
        </Menu>
      </Box>
    </AppBar>
  );
};

export default JazrAppBar;
