import React, { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  TextField,
  Stack,
  Box,
  Link,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useLoginMutation } from "src/api/auth";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { authState } from "src/store/auth";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export interface IFormInputs extends yup.InferType<typeof schema> {}

const Form: React.FC = () => {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth, setAuth] = useRecoilState(authState);

  const { mutate, status, isPending, data, error } = useLoginMutation();

  const { control, handleSubmit, formState } = useForm<IFormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (status === "success" && data) {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("signedInSuccessfully"),
        },
      ]);
      setAuth({ token: data.data.token, user: data.data.user });
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3} alignItems={"flex-start"}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("email")}
              {...field}
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              fullWidth
              size="small"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <FormControl
              {...field}
              variant="outlined"
              error={!!formState.errors.password}
              size="small"
              fullWidth
            >
              <InputLabel htmlFor="outlined-adornment-password">
                {t("password")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                aria-describedby="my-helper-text"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined fontSize="small" />
                      ) : (
                        <VisibilityOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("password")}
              />
              <FormHelperText id="my-helper-text">
                {formState.errors.password?.message}
              </FormHelperText>
            </FormControl>
          )}
        />

        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={1}
        >
          <FormControlLabel
            control={<Checkbox sx={{ paddingY: "0" }} defaultChecked />}
            label={t("rememberMe")}
          />

          <Link
            color={"secondary"}
            component={RouterLink}
            underline="always"
            to="/forgot-password"
          >
            {t("forgotPassword")}
          </Link>
        </Box>
        <LoadingButton
          loading={isPending}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
        >
          {t("signIn")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Form;
