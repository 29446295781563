import { Stack } from "@mui/material";
import UsersTable from "./table";

const InternalUsers = () => {
  return (
    <Stack p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <UsersTable />
    </Stack>
  );
};
export default InternalUsers;
