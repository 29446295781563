import React, { useState } from "react";
import { FilePond, FilePondProps, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Register the plugins
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

interface IProps {
  onUpload?: (files: any[]) => void;
  maxFiles?: number;
  maxFileSize?: number;
  acceptedFileTypes?: string[]
}

const FilePondUploader: React.FC<IProps> = ({
  onUpload = () => {},
  maxFiles,
  maxFileSize,
  acceptedFileTypes = []
}) => {
  const [files, setFiles] = useState<any[]>([]);

  return (
    <div>
      <FilePond
        files={files}
        onupdatefiles={(fileItems) => {
          setFiles(fileItems.map((fileItem) => fileItem.file));
          onUpload(fileItems.map((fileItem) => fileItem.file));
        }}
        allowMultiple={true}
        maxFiles={maxFiles}
        labelIdle={`Drop files here or <span class='filepond--label-action'>Browse</span> ${
          maxFileSize ? `<br />(max. ${maxFileSize} mega)` : ""
        } `}
        acceptedFileTypes={acceptedFileTypes}
        maxFileSize={maxFileSize ? `${maxFileSize}MB` : undefined}
      />
    </div>
  );
};

export default FilePondUploader;
