import { Box, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const SideBarItem = ({
  icon,
  label,
  path,
}: {
  icon?: any;
  label: string;
  path: string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMatchingPath = matchPath(path, pathname);
  const { t } = useTranslation("general");

  const handleNavigation = () => {
    navigate(path);
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#FFF8F4" } }}
      bgcolor={isMatchingPath ? "#FFF8F4" : ""}
      onClick={handleNavigation}
    >
      <Box
        bgcolor={isMatchingPath ? "primary.main" : ""}
        height={"40px"}
        width={"6px"}
        borderRadius={"0px 4px 4px 0px"}
      />
      <Stack direction={"row"} py={"12px"} px={"28px"} gap={1.5}>
        <SvgIcon color={isMatchingPath ? "primary" : "action"}>{icon}</SvgIcon>
        <Typography
          fontWeight={isMatchingPath ? 600 : 400}
          color={isMatchingPath ? "primary" : "black"}
        >
          {t(label)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default SideBarItem;
