import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Link, Typography } from "@mui/material";
import highlightOffIcon from "src/assets/svg-icons/high-light-off.svg";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

export function ConfirmPopup({
  open,
  handleClose,
  handleConfirm,
  item = "",
  isPending,
  warningText,
  externalUrl,
}: {
  open: boolean;
  handleClose: any;
  handleConfirm?: any;
  item?: string; // item to delete
  isPending?: boolean;
  warningText?: string;
  externalUrl?: string;
}) {
  const { t, i18n } = useTranslation("general");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <Box p={2} pt={5} textAlign={"center"}>
        <img height={96} src={highlightOffIcon} alt="" />
        <DialogTitle id="alert-dialog-title">
          <Typography color={"textPrimary"} fontSize={"26px"} fontWeight={600}>
            {t("delete")} {item && `" ${item} "`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography color={"textPrimary"} fontWeight={500}>
              {t("AreYouWantToDelete")}{" "}
              <Typography component={"span"} color={"tertiary.main"}>
                {item && `" ${item} "`}
              </Typography>{" "}
              {i18n.language === "en" ? "?" : "؟"}
            </Typography>
            {!!warningText ? (
              <Typography mt={1}>{warningText}</Typography>
            ) : null}
            {!!externalUrl ? <Link href={externalUrl} target="_blank">{t("watchVideo")}</Link> : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleClose}
            variant="outlined"
            color="tertiary"
          >
            {t("cancel")}
          </Button>
          <LoadingButton
            fullWidth
            onClick={handleConfirm}
            variant="contained"
            color="error"
            autoFocus
            loading={isPending}
          >
            {t("delete")}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
