import { ReactComponent as UserIcon } from "src/assets/svg-icons/side-bar/user.svg";
import { ReactComponent as RoleIcon } from "src/assets/svg-icons/side-bar/role.svg";
import { ReactComponent as FolderIcon } from "src/assets/svg-icons/side-bar/folder.svg";
import { ReactComponent as CarrotIcon } from "src/assets/svg-icons/side-bar/carrot.svg";
import { ReactComponent as CMSIcon } from "src/assets/svg-icons/side-bar/cms.svg";

export const sideBarItems = [
  {
    label: "internalUsers",
    icon: <UserIcon />,
    path: "/users",
  },
  {
    label: "rolesManagement",
    icon: <RoleIcon />,
    path: "/roles",
  },
  {
    label: "generalData",
    icon: <FolderIcon />,
    path: "/data",
  },
  {
    label: "jazrCustomers",
    icon: <CarrotIcon />,
    path: "/customers",
  },
  {
    label: "websiteCMS",
    icon: <CMSIcon />,
    path: "/cms",
  },
];
