import { Box, Hidden, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import LogoIcon from "src/assets/svg-icons/logo.svg";
import SideBarItem from "./sidebar-item";
import MobileSidebar from "./mobile-sidebar";
import { sideBarItems } from "./constants";
import JazrAppBar from "./app-bar";

export const Layout: React.FC = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      maxWidth={"100vw"}
    >
      {/* sidebar */}
      <Hidden mdDown>
        <Stack
          maxWidth={"252px"}
          minWidth={"252px"}
          py={3}
          borderRight={"1px solid #D0D5DD"}
          height={"100vh"}
          maxHeight={"100vh"}
          position={"sticky"}
          top={"0"}
        >
          <Box px={"40px"} mb={"48px"}>
            <img src={LogoIcon} width={90} alt="" />
          </Box>
          {sideBarItems.map((item) => {
            return (
              <SideBarItem
                key={item.path}
                label={item.label}
                icon={item.icon}
                path={item.path}
              />
            );
          })}
        </Stack>
      </Hidden>

      <Hidden mdUp>
        <MobileSidebar />
      </Hidden>

      {/* pages content */}
      <Box flexGrow={1}>
        <JazrAppBar />
        <Outlet />
      </Box>
    </Box>
  );
};
