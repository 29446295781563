import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/admin/edit.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddEditUserPopup from "../../add-edit-user";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const { t } = useTranslation("general");
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={t("edit")}>
        <IconButton onClick={handleOpenEditModal}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>
      {openEditModal && (
        <AddEditUserPopup
          userData={row}
          open={openEditModal}
          handleClose={handleCloseEditModal}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};
export default TableActions;
