import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Chip, TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateBranchMutation } from "src/api/settings/branches";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import TableActions from "./table-actions";
import { useGetCustomers } from "src/api/customers";

const CustomersTable = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("customers");

  const tableHeadText = [
    t("companyID"),
    t("clientName"),
    t("email"),
    t("phone"),
    t("branches"),
    t("packageName"),
    t("joinUsAt"),
    t("lastEdit"),
    t("status"),
    t("actions"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetCustomers({
    page: pageNumber + 1,
  });
  const { mutate, status, error } = useUpdateBranchMutation();

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  // success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={10} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {row.company?.reference_number}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.branches?.length}</TableCell>
                    <TableCell align="center">{row.package}</TableCell>
                    <TableCell align="center">{row.created_at}</TableCell>
                    <TableCell align="center">{row.last_edit_at}</TableCell>
                    <TableCell align="center">
                      <Chip
                        color={row.status ? "success" : "error"}
                        label={row.status ? t("active") : t("inactive")}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TableActions rowId={row.id} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default CustomersTable;
