import React from "react";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

interface propsTypes {
  rowsLength: number;
  cellsLength: number;
}

export const TableLoadingSkeleton: React.FC<propsTypes> = ({
  rowsLength = 0,
  cellsLength = 0,
}) => {
  const rows = Array.from(Array(rowsLength).keys());
  const cells = Array.from(Array(cellsLength).keys());
  return (
    <TableBody>
      {rows.map((item) => (
        <TableRow key={item} hover={false}>
          {cells.map((cell) =>
            // cell === 0 ? (
            //   <TableCell
            //     sx={{
            //       display: "flex",
            //       justifyContent: "space-between",
            //       alignItems: "center",
            //     }}
            //     key={cell}
            //   >
            //     <Skeleton
            //       sx={{ borderRadius: 40, width: 40, height: 50, mr: 3 }}
            //     />
            //     <Skeleton sx={{ flex: 1, height: 20 }}></Skeleton>
            //   </TableCell>
            // ) : (
              <TableCell key={cell}>
                <Skeleton sx={{ flex: 1, height: 20 }} />
              </TableCell>
            // )
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};
