import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  page?: number;
}
export const useGetUsersList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-admin-users-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/admin/admins`, { params });
    },
  });

export const useAddUserMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/admin/admins`, data);
    },
  });

export const useUpdateUserMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/admin/admins/${id}`, rest);
    },
  });
