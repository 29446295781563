import * as React from "react";
import { Button, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import englandIcon from "src/assets/svg-icons/flags/england.svg";
import saudiIcon from "src/assets/svg-icons/flags/saudi.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link as RouterLink } from "react-router-dom";
import { ConstLocalStorage } from "src/constants/_localStorage";
import { setLocalStoredData } from "src/utils/_localStorage";

export default function Footer() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t, i18n } = useTranslation("auth");

  const handleLanguageChange = (language: "en" | "ar") => {
    i18n.changeLanguage(language);
    setLocalStoredData(ConstLocalStorage.language, language);
    setAnchorEl(null);
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
    >
      <div>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{ p: 0, display: "flex", gap: 1 }}
        >
          <img src={i18n.language === "en" ? englandIcon : saudiIcon} alt="" />
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            textTransform={"capitalize"}
          >
            {i18n.language === "en" ? "English" : "العربية"}
          </Typography>
          <KeyboardArrowDownIcon color="action" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleLanguageChange("en")} sx={{ gap: 1 }}>
            <img src={englandIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              English
            </Typography>
          </MenuItem>
          <MenuItem onClick={() => handleLanguageChange("ar")} sx={{ gap: 1 }}>
            <img src={saudiIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              العربية
            </Typography>
          </MenuItem>
        </Menu>
      </div>

      <Stack spacing={"16px"} direction={"row"}>
        <Link
          color={"secondary"}
          component={RouterLink}
          underline="hover"
          to=""
          fontSize={"14px"}
        >
          {t("terms")}
        </Link>
        <Link
          color={"secondary"}
          component={RouterLink}
          underline="hover"
          to=""
          fontSize={"14px"}
        >
          {t("plans")}
          
        </Link>
        <Link
          color={"secondary"}
          component={RouterLink}
          underline="hover"
          to=""
          fontSize={"14px"}
        >
          {t("contactUs")}
        </Link>
      </Stack>
    </Stack>
  );
}
