import { Box, Drawer, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import DrawerIcon from "src/assets/svg-icons/drawer.svg";
import SideBarItem from "../sidebar-item";
import LogoIcon from "src/assets/svg-icons/logo.svg";
import { sideBarItems } from "../constants";

const MobileSidebar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box p={"14px 16px"}>
      <IconButton sx={{ p: "0" }} onClick={handleOpen}>
        <img width={32} height={32} src={DrawerIcon} alt="" />
      </IconButton>

      <Drawer anchor={"left"} open={open} onClose={handleClose}>
        <Stack
          maxWidth={"252px"}
          minWidth={"252px"}
          py={3}
          borderRight={"1px solid #D0D5DD"}
          height={"100vh"}
          maxHeight={"100vh"}
          position={"sticky"}
          top={"0"}
        >
          <Box px={"40px"} mb={"48px"}>
            <img src={LogoIcon} width={90} alt="" />
          </Box>
          {sideBarItems.map((item) => {
            return (
              <Box onClick={handleClose} key={item.path}>
                <SideBarItem
                  label={item.label}
                  icon={item.icon}
                  path={item.path}
                />
              </Box>
            );
          })}
        </Stack>
      </Drawer>
    </Box>
  );
};

export default MobileSidebar;
