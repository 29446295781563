import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  page?: number;
}

export const useGetPackages = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-packages", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/admin/packages`, {
        params: params ? params : {},
      });
    },
  });

export const useAddPackageMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { name: string; description: string; price: number; status: boolean }
  >({
    mutationFn: async (data) => {
      return apiClient.post(`/api/admin/packages`, data);
    },
  });

export const useUpdatePackageMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    {
      id: string;
      name: string;
      description: string;
      price: number;
      status: boolean;
    }
  >({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/admin/packages/${id}`, rest);
    },
  });

export const useGePackageById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-package-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/admin/packages/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useDeletePackageMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/admin/packages/${id}`);
    },
  });

export const useGetPackageSettings = () =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-packages-settings"],
    queryFn: async () => {
      return apiClient.get(`/api/admin/packageSettings/1`);
    },
  });

export const useUpdatePackageSettingsMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    {
      min_months: number;
      discount_percentage: number;
    }
  >({
    mutationFn: async (data) => {
      return apiClient.put(`/api/admin/packageSettings/1`, data);
    },
  });
