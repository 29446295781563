import { Box, Button, Stack, Typography } from "@mui/material";
import rolesManagementIcon from "src/assets/svg-icons/admin/roles-management.svg";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RolesTable from "./table";
import { useNavigate } from "react-router-dom";

const RolesManagement = () => {
  const { t } = useTranslation("rolesManagement");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/roles/add");
  };
  return (
    <Stack spacing={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={rolesManagementIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("rolesManagement")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <Stack spacing={1.5}>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={600}
            lineHeight={"19.2px"}
            color={"#475467"}
            textTransform={"uppercase"}
          >
            {t("rolesList")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleNavigate}
          >
            {t("addNewRole")}
          </Button>
        </Stack>
        <RolesTable />
      </Stack>
    </Stack>
  );
};
export default RolesManagement;
