import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    // translation: require("./locales/en/auth.json"),
    auth: require("./locales/en/auth.json"),
    general: require("./locales/en/general.json"),
    settings: require("./locales/en/settings.json"),
    profile: require("./locales/en/profile.json"),
    internalUsers: require("./locales/en/internal-users.json"),
    rolesManagement: require("./locales/en/roles-management.json"),
    customers: require("./locales/en/customers.json"),
    websiteCms: require("./locales/en/website-cms.json"),
  },
  ar: {
    // translation: require("./locales/ar/auth.json"),
    auth: require("./locales/ar/auth.json"),
    general: require("./locales/ar/general.json"),
    settings: require("./locales/ar/settings.json"),
    profile: require("./locales/ar/profile.json"),
    internalUsers: require("./locales/ar/internal-users.json"),
    rolesManagement: require("./locales/ar/roles-management.json"),
    customers: require("./locales/ar/customers.json"),
    websiteCms: require("./locales/ar/website-cms.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
