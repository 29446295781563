export const getLocalStoredData = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : undefined;
};

export const setLocalStoredData = (key: string, value: any): boolean => {
  try {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
    return true;
  } catch (error) {
    console.error(`Error storing local storage data for key "${key}":`, error);
    return false;
  }
};
