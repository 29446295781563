import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import profileIcon from "src/assets/svg-icons/profile.svg";
import RightArrow from "src/assets/svg-icons/admin/right-arrow.svg";
import emailIcon from "src/assets/svg-icons/email.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { authState } from "src/store/auth";

const MyProfile = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("profile");
  const [auth, setAuth] = useRecoilState(authState);
  const { user } = auth;

  const initials = user?.name
    .split(" ")
    .map((word: string) => word.charAt(0))
    .join("");

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <Stack gap={3} p={{ xs: 2, sm: "30px 200px" }}>
      <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={profileIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("myProfile")}
        </Typography>
      </Stack>

      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToDashboard}
          sx={{ cursor: "pointer" }}
        >
          {t("home")}
        </Typography>
        <img
          src={RightArrow}
          alt=""
          style={{
            transform: i18n.language === "en" ? "none" : "scaleX(-1)",
          }}
        />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {t("profile")}
          </Typography>
        </Box>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <Box>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          spacing={3}
          bgcolor={"#F9FAFB"}
          borderRadius={"8px 8px 0px 0px"}
          p={2}
        >
          <Avatar
            sx={{ borderRadius: 1, width: "140px", height: "140px" }}
            variant="square"
          >
            {/* <Typography textTransform={"uppercase"}>{initials}</Typography> */}
          </Avatar>
          <Box>
            <Typography
              fontSize={"23px"}
              fontWeight={600}
              lineHeight={"27.6px"}
            >
              {user.name}
            </Typography>
            <Stack direction={"row"} spacing={1}>
              <img src={emailIcon} width={"20px"} alt="" />

              <Typography
                fontSize={"16px"}
                color={"#98A2B3"}
                lineHeight={"19.2px"}
              >
                {user.email}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack
          borderRadius={"0px 0px 8px 8px"}
          border={"1px solid #EAECF0"}
          bgcolor={"#FFF"}
          p={3}
          spacing={"12px"}
        >
          <Typography
            align="center"
            fontSize={"16px"}
            color={"#475467"}
            lineHeight={"19.2px"}
          >
            {t("profileDetails")}
          </Typography>
          {/* divider */}
          <Box bgcolor={"#EAECF0"} width={"100%"} height={"1px"} />

          <Grid container bgcolor={"#F9FAFB"} p={2} spacing={2}>
            <Grid item xs={4} sm={2}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#344054"}
              >
                {t("fullName")}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                fontSize={{ xs: "12 !important", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#101828"}
              >
                {user.name}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#344054"}
              >
                {t("email")}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#101828"}
              >
                {user.email}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#344054"}
              >
                {t("company")}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#101828"}
              >
                {user.company.name}
              </Typography>
            </Grid>

            <Grid item xs={4} sm={2}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#344054"}
              >
                {t("companyId")}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                fontSize={{ xs: "12", lg: "16px" }}
                fontWeight={600}
                lineHeight={"19.2px "}
                color={"#101828"}
              >
                {user.company.reference_number}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Stack>
  );
};

export default MyProfile;
