import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";
import { IPermissionsDataResponse } from "src/types/settings";

interface IParams {
  page?: number;
}

export const useGetRoles = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-admin-roles", params?.page],
    queryFn: async () => {
      return apiClient.get(`/api/admin/roles`, {
        params: params ? params : {},
      });
    },
  });

export const useGetPermissions = () =>
  useQuery<void, APIErrorResponse, IPermissionsDataResponse>({
    queryKey: ["get-admin-permissions"],
    queryFn: async () => {
      return apiClient.get(`/api/permissions?is_admin=1`);
    },
  });

export const useAddRoleMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { role: { name: string }; permissions: number[] }
  >({
    mutationFn: async (data) => {
      return apiClient.post(`/api/admin/roles`, data);
    },
  });

export const useUpdateRoleMutation = () =>
  useMutation<
    void,
    APIErrorResponse,
    { id: string; role: { name: string }; permissions: number[] }
  >({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/admin/roles/${id}`, rest);
    },
  });

export const useGetRoleById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<void, APIErrorResponse, any>({
    queryKey: ["get-admin-role-by-id"],
    queryFn: async () => {
      return apiClient.get(`/api/admin/roles/${id}`);
    },
    gcTime: 0,
    enabled,
  });

export const useDeleteRoleMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/admin/roles/${id}`);
    },
  });
