import { Box, Stack, Typography } from "@mui/material";
import jazarCustomersIcon from "src/assets/svg-icons/admin/jazar.svg";
import { useTranslation } from "react-i18next";
import CustomersTable from "./table";

const JazarCustomers = () => {
  const { t } = useTranslation("customers");

  return (
    <Stack spacing={3} p={{ xs: 2, sm: "8px 32px 32px 32px" }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={jazarCustomersIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("jazarCustomers")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />
      <Stack spacing={1.5}>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontWeight={600}
            lineHeight={"19.2px"}
            color={"#475467"}
            textTransform={"uppercase"}
          >
            {t("customersList")}
          </Typography>
        </Stack>
        <CustomersTable />
      </Stack>
    </Stack>
  );
};
export default JazarCustomers;
