import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import PackageListTable from "./table";
import { useNavigate } from "react-router-dom";

const PackageList = () => {
  const { t } = useTranslation("websiteCms");
  const navigate = useNavigate();
  const navigateToAddPackage = () => {
    navigate("/cms/add-package");
  };

  return (
    <Stack spacing={1.5}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("packageList")}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={navigateToAddPackage}
        >
          {t("addNewPackage")}
        </Button>
      </Stack>
      <PackageListTable />
    </Stack>
  );
};

export default PackageList;
