import { AlertColor } from "@mui/material";
import { atom } from "recoil";

export interface Notification {
  type: AlertColor;
  message: string;
}

export const notificationsState = atom<Notification[]>({
  key: "notifications", // unique ID
  default: [],
});
