import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/admin/edit.svg";
import DeleteIcon from "src/assets/svg-icons/admin/delete.svg";
import { useTranslation } from "react-i18next";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { useDeleteRoleMutation } from "src/api/roles";
import { useEffect, useState } from "react";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";

const TableActions = ({
  rowId,
  rowName,
  refetch,
}: {
  rowId: number;
  rowName: string;
  refetch: Function;
}) => {
  const { t } = useTranslation("general");
  const navigate = useNavigate();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);

  // APIS
  const { mutate, isPending, status, error } = useDeleteRoleMutation();

  const navigateToEditPage = () => {
    navigate(`/roles/${rowId}/edit`);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(rowId);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={t("delete")}>
        <IconButton onClick={handleClickOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("edit")}>
        <IconButton onClick={navigateToEditPage}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title={t("details")}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}

      <ConfirmPopup
        open={open}
        item={rowName}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
