import { AxiosError } from "axios";
import { ConstLocalStorage } from "src/constants/_localStorage";

export function genericErrorHandler(error: AxiosError): any {
  const statusCode = error.response ? error.response.status : null;
  if (!statusCode) {
    alert("Opps! Connection error,Please check your network connection.");
  } else {
    if (statusCode === 401) {
      //Log User Out
      localStorage.removeItem(ConstLocalStorage.token);
      localStorage.removeItem(ConstLocalStorage.user);
      window.location.reload();
    } else if (statusCode > 499) {
      alert("Opps! server error,We're working on it, Please try again later");
    }
    return error;
  }
}
